{
   "pages" : {
      "about" : "About JaponicusDB",
      "about/citing-japonicusdb" : "Citing JaponicusDB",
      "about/cookie-policy" : "JaponicusDB Cookie Policy",
      "about/data-sources" : "JaponicusDB Data Sources",
      "about/index" : "About JaponicusDB",
      "about/japonicusdb-sab" : "JaponicusDB Scientific Advisory Board",
      "about/japonicusdb-staff" : "Contact us",
      "about/privacy-policy" : "JaponicusDB Privacy Policy",
      "about/published-by-japonicusdb" : "JaponicusDB Publications",
      "about/resource-metrics" : "Resource Metrics",
      "about/terms-of-use" : "JaponicusDB Terms of Use",
      "browse-curation" : "Browse curation",
      "browse-curation/disease-slim" : "Monarch Disease Ontology slim",
      "browse-curation/fission-yeast-bp-go-slim-terms" : "Fission yeast GO Biological Process slim",
      "browse-curation/fission-yeast-cc-go-slim-terms" : "Fission yeast GO Cellular Component slim",
      "browse-curation/fission-yeast-go-slimming-tips" : "JaponicusDB GO Slim Usage Tips",
      "browse-curation/fission-yeast-mf-go-slim-terms" : "Fission yeast GO Molecular Function slim",
      "browse-curation/fission-yeast-phenotype-ontology" : "Fission Yeast Phenotype Ontology",
      "browse-curation/fypo-slim" : "Fission Yeast Phenotype Ontology slim",
      "browse-curation/index" : "Browse curation",
      "community" : "Fission Yeast Community",
      "community/index" : "Fission Yeast Community",
      "community/internet-resources" : "Ontologies",
      "datasets" : "Datasets",
      "datasets/index" : "Datasets",
      "documentation" : "JaponicusDB Web Site Help & Documentation",
      "documentation/JBrowse_quick_start" : "Getting started with JaponicusDB JBrowse",
      "documentation/advanced-search" : "Advanced search",
      "documentation/annotation-extension-relation-display" : "Annotation extension relation display",
      "documentation/controlled-curation" : "JaponicusDB controlled curation",
      "documentation/data-submission-form-for-HTP-sequence-linked-data" : "HTP sequence-linked data submission instructions",
      "documentation/gene-page-basic-information" : "Gene page: Basic information",
      "documentation/gene-page-external-references" : "Gene page: External references",
      "documentation/gene-page-gene-expression" : "Gene page: Gene expression",
      "documentation/gene-page-gene-ontology" : "Gene page: Gene Ontology",
      "documentation/gene-page-gene-structure-history" : "Gene page: Gene structure history",
      "documentation/gene-page-literature" : "Gene page: Literature",
      "documentation/gene-page-modifications" : "Gene page: Modifications",
      "documentation/gene-page-phenotypes" : "Gene page: Phenotypes",
      "documentation/gene-page-protein-features" : "Gene page: Protein domains and properties",
      "documentation/gene-page-protein-features-widget" : "Gene page: Protein feature widget",
      "documentation/gene-page-sequence" : "Gene page: Sequence",
      "documentation/gene-page-target" : "Gene page: Target of",
      "documentation/gene-page-transcript" : "Gene page: Transcript",
      "documentation/genetic-and-physical-interactions" : "Genetic and physical interactions",
      "documentation/genotype-page" : "Genotype pages",
      "documentation/getting-started" : "Getting started",
      "documentation/go-cam-pathway-models" : "GO-CAM curated biological pathways",
      "documentation/high-confidence-physical-interaction-network" : "High Confidence Physical Interaction Network (HCPIN)",
      "documentation/id-mapper" : "Identifier mapper",
      "documentation/index" : "JaponicusDB Web Site Help & Documentation",
      "documentation/misc-sequence-features" : "Miscellaneous sequence features",
      "documentation/modification-data-bulk-upload-format" : "Modification data bulk upload format",
      "documentation/motif-search" : "Peptide motif search",
      "documentation/ontology-term-page" : "Ontology term pages",
      "documentation/orthologs" : "Ortholog curation",
      "documentation/phenotype-data-bulk-upload-format" : "Phenotype data bulk upload format",
      "documentation/pombase-go-slim-documentation" : "GO Slims - Gene Ontology subsets",
      "documentation/pombase-references" : "PomBase References (PB_REFs)",
      "documentation/publication-page" : "Publication pages",
      "documentation/qualitative-gene-expression-data-bulk-upload-format" : "Qualitative gene expression bulk upload file format",
      "documentation/quantitative-gene-expression-data-bulk-upload-format" : "Quantitative gene expression bulk upload file format",
      "documentation/quick-little-tool" : "Quick Little Tool",
      "documentation/simple-search-documentation" : "Simple search",
      "documentation/taxonomic-conservation" : "Taxonomic conservation",
      "downloads" : "Downloads",
      "downloads/chado-database-dumps" : "Chado database dumps",
      "downloads/genome-datasets" : "Genome sequence and features",
      "downloads/go-annotations" : "GO annotations",
      "downloads/index" : "Downloads",
      "downloads/modifications" : "Protein modifications",
      "downloads/names-and-identifiers" : "JaponicusDB name and identifier mappings",
      "downloads/phenotype-annotations" : "Phenotype annotations",
      "downloads/protein-datasets" : "Protein datasets",
      "faq" : "Frequently asked questions",
      "faq/can-i-access-japonicusdb-via-api" : "Can I access JaponicusDB via an API?",
      "faq/can-i-access-japonicusdb-via-sql" : "Can I access JaponicusDB via SQL?",
      "faq/can-i-convert-file-gff-gtf" : "Can I convert a file from GFF to GTF?",
      "faq/can-i-convert-file-gtf-gff3" : "Can I convert a file from GTF to GFF3?",
      "faq/can-i-convert-ids-other-databases-or-japonicusdb-ids" : "Can I convert IDs from other databases to or from JaponicusDB IDs?",
      "faq/can-i-do-enrichment-analysis-using-phenotypes" : "Can I do an enrichment analysis using phenotypes?",
      "faq/can-i-download-all-s.-japonicus-phenotype-data" : "Can I download all *S. japonicus* phenotype data?",
      "faq/can-i-download-sequence-whole-chromosome" : "Can I download the sequence for a whole chromosome?",
      "faq/can-i-download-sequences-many-genes-once-including-flanking-regions" : "Can I download sequences for many genes at once, including flanking regions?",
      "faq/can-i-export-data-sequence-region-genome-browser" : "Can I export data for a sequence region from the genome browser?",
      "faq/can-i-find-replication-origins-japonicusdb" : "Can I find replication origins in JaponicusDB?",
      "faq/can-i-generate-comprehensive-restriction-enzyme-map-genome-japonicusdb" : "Can I generate a comprehensive restriction enzyme map of the genome in JaponicusDB?",
      "faq/can-i-get-cdna-sequences-s.-japonicus" : "Can I get cDNA sequences for *S. japonicus*?",
      "faq/can-i-get-file-specific-set-genome-features" : "Can I get a file with a specific set of genome features?",
      "faq/can-i-get-list-essential-japonicus-genes" : "Can I get a list of essential japonicus genes?",
      "faq/can-i-get-list-systematic-ids-primary-names-synonyms-and-gene-products-s.-japonicus" : "Can I get a list of systematic IDs, primary names, synonyms and gene products in *S. japonicus*?",
      "faq/can-i-obtain-dump-all-curated-data-japonicusdb" : "Can I obtain a dump of all curated data in JaponicusDB?",
      "faq/can-i-provide-list-genes-search-on" : "Can I provide a list of genes to search on?",
      "faq/can-i-retrieve-annotations-metabolic-databases-such-as-ec-metacyc-reactome-or-rhea-s.-japonicus-genes" : "Can I retrieve annotations to metabolic databases such as EC, MetaCyc, Reactome, or Rhea for *S. japonicus* genes?",
      "faq/can-i-retrieve-functional-annotations-genes-list" : "Can I retrieve functional annotations for genes in a list?",
      "faq/can-i-retrieve-list-s.-japonicus-go-slim-terms" : "Can I retrieve a list of the S. japonicus GO slim terms?",
      "faq/can-i-search-gene-list-and-retrieve-results-same-order-as-input-list" : "Can I search for a gene list and retrieve results in the same order as in the input list?",
      "faq/can-i-search-genes-based-on-conservation-different-taxa" : "Can I search for genes based on conservation in different taxa?",
      "faq/can-i-search-japonicusdb-protein-features" : "Can I search JaponicusDB for protein features?",
      "faq/can-i-share-my-search-results" : "Can I share my search results?",
      "faq/can-i-use-blast-find-s.-japonicus-sequences-similar-my-query-sequence" : "Can I use BLAST to find *S. japonicus* sequences similar to my query sequence?",
      "faq/can-i-use-blast-search-short-sequences" : "Can I use BLAST to search for short sequences?",
      "faq/can-i-use-wild-card-beginning-search-string" : "Can I use a wild card at the beginning of a search string?",
      "faq/can-i-view-my-data-privately-genome-browser" : "Can I view my data privately in the genome browser?",
      "faq/can-i-view-my-protein-interest-multiple-alignment-related-proteins-other-species" : "Can I view my protein of interest in a multiple alignment with related proteins in other species?",
      "faq/can-i-view-nucleotide-sequence-genome-browser" : "Can I view nucleotide sequence in the genome browser?",
      "faq/community" : "Category: Community",
      "faq/data-submission-and-formats" : "Category: Data submission and formats",
      "faq/do-i-need-password-download-ftp-site" : "Do I need a password to download from the FTP site?",
      "faq/finding-data" : "Category: Finding data",
      "faq/gene-page" : "Category: Gene page",
      "faq/genome-browser" : "Category: Genome browser",
      "faq/genome-statistics-and-lists" : "Category: Genome statistics and lists",
      "faq/help-canto-isn-t-working-me.-do-you-have-any-suggestions" : "Help! Canto isn't working for me. Do you have any suggestions?",
      "faq/how-can-i-browse-phenotype-ontology-fypo" : "How can I browse the phenotype ontology (FYPO)?",
      "faq/how-can-i-display-sequence-region-using-sequence-coordinates-genome-browser" : "How can I display a sequence region using sequence coordinates in the genome browser?",
      "faq/how-can-i-find-all-genes-have-given-mutant-phenotype" : "How can I find all of the genes that have a given mutant phenotype?",
      "faq/how-can-i-find-all-s.-japonicus-proteins-particular-protein-family-or-have-particular-domain" : "How can I find all *S. japonicus* proteins in a particular protein family, or that have a particular domain?",
      "faq/how-can-i-find-all-sequence-features-region-using-chromosome-coordinates" : "How can I find all sequence features in a region using chromosome coordinates?",
      "faq/how-can-i-find-genes-region-using-chromosome-coordinates" : "How can I find genes in a region using chromosome coordinates?",
      "faq/how-can-i-find-genes-specific-activity" : "How can I find genes with a specific activity?",
      "faq/how-can-i-find-modifications-my-protein-interest" : "How can I find modifications for my protein of interest?",
      "faq/how-can-i-find-mutants-increased-or-decreased-sensitivity-chemicals" : "How can I find mutants with increased or decreased sensitivity to chemicals",
      "faq/how-can-i-find-orthologs-between-s.-japonicus-and-other-schizosaccharomyces-species" : "How can I find orthologs between *S. japonicus* and other *Schizosaccharomyces* species?",
      "faq/how-can-i-find-protein-localization-data" : "How can I find protein localization data?",
      "faq/how-can-i-find-proteins-have-transmembrane-domains" : "How can I find proteins that have transmembrane domains?",
      "faq/how-can-i-find-rrna-genes" : "How can I find rRNA genes?",
      "faq/how-can-i-find-s.-japonicus-ortholog-s-human-gene" : "How can I find *S. japonicus* ortholog(s) of a human gene?",
      "faq/how-can-i-find-s.-japonicus-orthologs-species-other-than-human-and-s.-cerevisiae" : "How can I find *S. japonicus* orthologs for species other than human and *S. cerevisiae*?",
      "faq/how-can-i-find-significant-shared-fypo-annotations-genes-list" : "How can I find significant shared FYPO annotations for genes in a list?",
      "faq/how-can-i-find-significant-shared-go-annotations-genes-list" : "How can I find significant shared GO annotations for genes in a list?",
      "faq/how-can-i-find-snorna-genes" : "How can I find snoRNA genes?",
      "faq/how-can-i-find-transposons-genome" : "How can I find transposons in the genome?",
      "faq/how-can-i-get-my-data-into-japonicusdb" : "How can I get my data into JaponicusDB?",
      "faq/how-can-i-identify-all-genes-affect-process" : "How can I identify all of the genes that affect a process?",
      "faq/how-can-i-obtain-list-human-and-s.-japonicus-orthologs" : "How can I obtain the list of human and *S. japonicus* orthologs?",
      "faq/how-can-i-retrieve-all-s.-japonicus-genes" : "How can I retrieve all *S. japonicus* genes?",
      "faq/how-can-i-retrieve-all-s.-japonicus-protein-coding-genes" : "How can I retrieve all *S. japonicus* protein-coding genes?",
      "faq/how-can-i-retrieve-gene-sequence-including-upstream-and-downstream-sequences" : "How can I retrieve a gene sequence, including upstream and downstream sequences?",
      "faq/how-can-i-retrieve-intron-coordinates-or-sequences" : "How can I retrieve intron coordinates or sequences?",
      "faq/how-can-i-retrieve-sequence-coordinates-all-features-particular-type" : "How can I retrieve sequence coordinates for all features of a particular type?",
      "faq/how-can-i-retrieve-sequence-region-using-sequence-coordinates" : "How can I retrieve the sequence of a region using sequence coordinates?",
      "faq/how-can-i-retrieve-sequences-fasta-file-custom-headers" : "How can I retrieve sequences in a FASTA file with custom headers?",
      "faq/how-can-i-retrieve-sequences-non-protein-coding-genes" : "How can I retrieve sequences for non-protein-coding genes?",
      "faq/how-can-i-retrieve-utr-sequences" : "How can I retrieve UTR sequences?",
      "faq/how-can-i-search-genes-involved-both-go-process-and-regulation-process" : "How can I search for genes involved in both a GO process and regulation of the process?",
      "faq/how-can-i-search-or-browse-go-annotations" : "How can I search or browse GO annotations?",
      "faq/how-can-i-search-s.-cerevisiae-ortholog-s-homolog-s-s.-japonicus-gene" : "How can I search for the *S. cerevisiae* ortholog(s)/homolog(s) of an *S. japonicus* gene?",
      "faq/how-can-i-see-nucleotide-level-similarity-between-s.-japonicus-and-other-schizosaccharomyces-species" : "How can I see nucleotide-level similarity between *S. japonicus* and other *Schizosaccharomyces* species?",
      "faq/how-can-i-show-or-hide-tracks-genome-browser" : "How can I show or hide tracks in the genome browser?",
      "faq/how-can-i-submit-high-throughput-data-japonicusdb" : "How can I submit high-throughput data to JaponicusDB?",
      "faq/how-can-i-use-artemis-browse-genome" : "How can I use Artemis to browse the genome?",
      "faq/how-can-i-use-go-slims-s.-japonicus" : "How can I use GO slims with *S. japonicus*?",
      "faq/how-do-i-change-track-scale-jbrowse" : "How do I change the track scale in JBrowse?",
      "faq/how-do-i-cite-data-japonicusdb-how-do-i-cite-genome-sequence-comparison-data-etc." : "How do I cite data from JaponicusDB? How do I cite the genome sequence, comparison data, etc.?",
      "faq/how-do-i-reserve-gene-name" : "How do I reserve a gene name?",
      "faq/index" : "Frequently asked questions",
      "faq/orthology" : "Category: Orthology",
      "faq/there-any-programmatic-access-japonicusdb-data" : "Is there any programmatic access to JaponicusDB data?",
      "faq/there-list-protein-complexes-s.-japonicus-and-their-subunits" : "Is there a list of protein complexes in *S. japonicus*, and their subunits?",
      "faq/tools-and-resources" : "Category: Tools and resources",
      "faq/using-ontologies" : "Category: Using ontologies",
      "faq/what-annotation-extension" : "What is an annotation extension?",
      "faq/what-bam-format" : "What is BAM format?",
      "faq/what-bed-format" : "What is BED format?",
      "faq/what-bedgraph-format" : "What is bedGraph format?",
      "faq/what-bigbed-format" : "What is bigBed format?",
      "faq/what-bigwig-format" : "What is bigWig format?",
      "faq/what-file-formats-can-i-use-submit-high-throughput-data" : "What file formats can I use to submit high-throughput data?",
      "faq/what-gff3" : "What is GFF3?",
      "faq/what-go-term-enrichment-how-can-i-do-it-my-genes" : "What is GO term enrichment? How can I do it for my genes?",
      "faq/what-psl-format" : "What is PSL format?",
      "faq/what-vcf" : "What is VCF?",
      "faq/what-wig-format" : "What is WIG format?",
      "faq/when-was-genomic-sequence-last-updated" : "When was the genomic sequence last updated?",
      "faq/where-can-i-download-genome-sequence" : "Where can I download the genome sequence?",
      "faq/where-can-i-find-basic-statistics-on-s.-japonicus-genome-such-as-genome-size-total-number-genes-mean-intergenic-distance-etc." : "Where can I find basic statistics on the *S. japonicus* genome, such as genome size, total number of genes, mean intergenic distance, etc.?",
      "faq/where-can-i-find-information-about-japonicusdb-data-versions" : "Where can I find information about JaponicusDB data versions?",
      "faq/where-do-i-find-more-information-about-s.-japonicus" : "Where do I find more information about *S. japonicus*?",
      "faq/why-are-go-annotations-different-between-japonicusdb-and-uniprot-goa" : "Why are GO annotations different between JaponicusDB and UniProt/GOA?",
      "faq/why-are-go-terms-missing-downloadable-annotation-file" : "Why are GO terms missing from the downloadable annotation file?",
      "faq/why-are-pombelist-messages-delayed-why-don-t-i-get-pombelist-emails-promptly" : "Why are pombelist messages delayed? Why don't I get pombelist emails promptly?",
      "faq/why-are-some-genes-abnormal-phenotype-annotated-corresponding-go-process-while-others-are-not" : "Why are some genes with an abnormal phenotype annotated to the corresponding GO process while others are not?",
      "faq/why-are-some-genes-annotated-both-viable-and-inviable-phenotypes" : "Why are some genes annotated to both viable and inviable phenotypes?",
      "help" : "Help",
      "help/index" : "Help",
      "news" : "News archive",
      "news/2021-07-22-welcome-to-japonicusdb" : "JaponicusDB: A database for Schizosaccharomyces japonicus",
      "news/2021-08-18-updated-japonicus-genes" : "Updated S. japonicus gene set",
      "news/2021-09-01-official-japonicusdb-release" : "Official JaponicusDB release",
      "news/2021-09-27-Genetics-database-pubs" : "JaponicusDB & PomBase preprints",
      "news/2021-10-18-new-protein-feature-searches" : "Coils, disorder, and more: new protein feature queries",
      "news/2021-11-23-interpro-v87" : "JaponicusDB now uses InterPro Version 87.0",
      "news/2022-02-02-pombase-and-japonicusdb-papers" : "PomBase & JaponicusDB publications in the GENETICS MOD reports special issue",
      "news/2022-03-07-japonicus-annotation-in-go-db" : "JaponicusDB disseminates data to the Gene Ontology database",
      "news/2022-03-12-interprop-v88" : "JaponicusDB now uses InterPro Version 88.0",
      "news/2022-04-04-genetics-publications" : "Making biological knowledge useful for humans and machines",
      "news/2022-12-09-rna-length-advanced-search" : "Querying by RNA length in the Advanced Search tool",
      "news/2023-05-23-alphafold-on-gene-pages" : "AlphaFold protein structure on gene pages",
      "news/2023-05-23-pdb-structures" : "Experimental protein structures from PDB on gene pages",
      "news/2023-06-19-rhea-reaction-diagrams" : "Reaction diagrams on GO molecular function term pages",
      "news/2023-07-27-protein-feature-viewer" : "Protein feature viewer added to gene pages",
      "news/2023-10-10-pombetalks-oct-18th" : "Next pombeTalks: Wednesday, October 18th",
      "news/index" : "News archive",
      "status" : "Genome Status",
      "status/centromeres" : "Centromeres",
      "status/gene-coordinate-changes" : "Gene coordinate changes",
      "status/genome-overview" : "Genome overview",
      "status/index" : "Genome Status",
      "status/mating-type-region" : "Mating type region",
      "status/new-and-removed-genes" : "New and removed genes",
      "status/priority-unstudied-genes" : "Priority unstudied genes",
      "status/statistics" : "Genome statistics",
      "status/telomeres" : "Telomeres",
      "status/unmapped_genes" : "Unmapped genes",
      "submit-data" : "JaponicusDB Data Submission",
      "submit-data/bulk-annotation" : "Annotation dataset submission",
      "submit-data/gene-names" : "Fission yeast gene name registry",
      "submit-data/gene-naming-committee-members" : "S. japonicus Gene Naming Committee members",
      "submit-data/gene-naming-guidelines" : "Fission yeast gene naming guidelines",
      "submit-data/index" : "JaponicusDB Data Submission"
   }
}
